import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import {
  createAddressSlice,
  createMemberSlice,
  createPaymentErrorSlice,
  createProductSlice,
  createSignupSlice,
  type AddressSlice,
  type MemberSlice,
  type PaymentErrorSlice,
  type ProductSlice,
  type SignupSlice,
} from "apps-common/store";

export type SignupStore = AddressSlice &
  MemberSlice &
  PaymentErrorSlice &
  ProductSlice &
  SignupSlice;

export const useStore = create<SignupStore>()(
  persist(
    (...a) => ({
      ...createAddressSlice(...a),
      ...createMemberSlice(...a),
      ...createPaymentErrorSlice(...a),
      ...createProductSlice(...a),
      ...createSignupSlice(...a),
    }),
    { name: "msf", storage: createJSONStorage(() => sessionStorage) },
  ),
);
