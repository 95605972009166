import { createGlobalStyle } from "styled-components";

import AkkuratLLBlackWoff from "../fonts/AkkuratLL-Black.woff";
import AkkuratLLBlackWoff2 from "../fonts/AkkuratLL-Black.woff2";
import AkkuratLLBlackItalicWoff from "../fonts/AkkuratLL-BlackItalic.woff";
import AkkuratLLBlackItalicWoff2 from "../fonts/AkkuratLL-BlackItalic.woff2";
import AkkuratLLBoldWoff from "../fonts/AkkuratLL-Bold.woff";
import AkkuratLLBoldWoff2 from "../fonts/AkkuratLL-Bold.woff2";
import AkkuratLLBoldItalicWoff from "../fonts/AkkuratLL-BoldItalic.woff";
import AkkuratLLBoldItalicWoff2 from "../fonts/AkkuratLL-BoldItalic.woff2";
import AkkuratLLItalicWoff from "../fonts/AkkuratLL-Italic.woff";
import AkkuratLLItalicWoff2 from "../fonts/AkkuratLL-Italic.woff2";
import AkkuratLLLightWoff from "../fonts/AkkuratLL-Light.woff";
import AkkuratLLLightWoff2 from "../fonts/AkkuratLL-Light.woff2";
import AkkuratLLLightItalicWoff from "../fonts/AkkuratLL-LightItalic.woff";
import AkkuratLLLightItalicWoff2 from "../fonts/AkkuratLL-LightItalic.woff2";
import AkkuratLLRegularWoff from "../fonts/AkkuratLL-Regular.woff";
import AkkuratLLRegularWoff2 from "../fonts/AkkuratLL-Regular.woff2";
import AkkuratLLThinWoff from "../fonts/AkkuratLL-Thin.woff";
import AkkuratLLThinWoff2 from "../fonts/AkkuratLL-Thin.woff2";
import AkkuratLLThinItalicWoff from "../fonts/AkkuratLL-ThinItalic.woff";
import AkkuratLLThinItalicWoff2 from "../fonts/AkkuratLL-ThinItalic.woff2";

export const GlobalStyles = createGlobalStyle(
  ({ theme }) => `
  @font-face {
    font-family: 'Akkurat LL';
    font-weight: 100;
    src: url('${AkkuratLLThinWoff2}') format('woff2'),
         url('${AkkuratLLThinWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-weight: 200 300;
    src: url('${AkkuratLLLightWoff2}') format('woff2'),
         url('${AkkuratLLLightWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-weight: 400 500;
    src: url('${AkkuratLLRegularWoff2}') format('woff2'),
         url('${AkkuratLLRegularWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-weight: 600 800;
    src: url('${AkkuratLLBoldWoff2}') format('woff2'),
         url('${AkkuratLLBoldWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-weight: 900 1000;
    src: url('${AkkuratLLBlackWoff2}') format('woff2'),
         url('${AkkuratLLBlackWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-style: italic;
    font-weight: 100;
    src: url('${AkkuratLLThinItalicWoff2}') format('woff2'),
         url('${AkkuratLLThinItalicWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-style: italic;
    font-weight: 200 300;
    src: url('${AkkuratLLLightItalicWoff2}') format('woff2'),
         url('${AkkuratLLLightItalicWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-style: italic;
    font-weight: 400 500;
    src: url('${AkkuratLLItalicWoff2}') format('woff2'),
         url('${AkkuratLLItalicWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-style: italic;
    font-weight: 600 800;
    src: url('${AkkuratLLBoldItalicWoff2}') format('woff2'),
         url('${AkkuratLLBoldItalicWoff}')) format('woff');
  }

  @font-face {
    font-family: 'Akkurat LL';
    font-style: italic;
    font-weight: 900 1000;
    src: url('${AkkuratLLBlackItalicWoff2}') format('woff2'),
         url('${AkkuratLLBlackItalicWoff}')) format('woff');
  }

  body,
  html {
    margin: 0;
    padding: 0;
    background: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  * {
    font-family: ${theme.fonts};
    box-sizing: border-box;
  }
`,
);
