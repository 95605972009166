import { gql } from "graphql-request";

import {
  PaymentMethod,
  Subscription,
  MembershipType,
  Contact,
  MembershipOfferings,
} from "../types";
import { axiosQuery } from "../utils/axiosQuery";

export interface GetAccountResponse {
  account: {
    email: string;
    createdAt: string | null;
    dunningLevel: number | null;
    shipToContact: Contact | null;
    billToContact: Contact | null;
    membershipType: MembershipType | null;
    currentSubscription: Subscription | null;
    subscriptions: Subscription[];
    paymentMethods: PaymentMethod[];
    isValidCurrency: boolean; // checks if currency is valid to update PM or rate plan
  };
  membershipOffering: MembershipOfferings;
}

const GET_ACCOUNT_QUERY = gql`
  query GetAccount {
    membershipOffering {
      prepaidMonths
      membershipStatus {
        type
        subscriptionFound
        signupComplete
      }
      channel
      entitlement {
        type
      }
      customerId
      customerName
      products {
        name
        channel
        trial {
          length
          periodType
        }
        membership {
          periodType
          length
        }
        taxMode
        countries {
          code
          pricing {
            currency
            amount
          }
        }
      }
    }
    account {
      email
      dunningLevel
      createdAt
      shipToContact {
        firstName
        lastName
        address1
        address2
        city
        country
        postalCode
        state
        personalEmail
        addressValidated
      }
      billToContact {
        firstName
        lastName
        address1
        address2
        city
        country
        postalCode
        state
        personalEmail
        addressValidated
      }
      paymentMethods {
        ... on ApiCreditCardPaymentMethod {
          type
          isDefault
          status
          cardNumber
          isExpired
          isReusableInRenew
          cardType
        }
        ... on ApiSikaHealthPaymentMethod {
          type
          isDefault
          status
          cardNumber
          isExpired
          isReusableInRenew
          cardType
        }
        ... on ApiPayPalPaymentMethod {
          type
          isDefault
          status
          isReusableInRenew
        }
      }
      membershipType
      isValidCurrency
      currentSubscription {
        id
        channel
        subscriptionType
        renewalState
        subscriptionState
        nextBillingDate
        nextPaymentDate
        endDate
        startDate
        pendingCancellation
        recurringFee {
          amount
          billingPeriod
          currency
        }
        alternativeBillingPeriods {
          amount
          billingPeriod
          currency
        }
        prepaidPeriods {
          current {
            start
            end
            lengthInMonths
          }
          future {
            start
            end
            lengthInMonths
          }
        }
      }
      subscriptions {
        id
        channel
        subscriptionType
        renewalState
        subscriptionState
        nextBillingDate
        endDate
        startDate
        pendingCancellation
        recurringFee {
          amount
          billingPeriod
          currency
        }
        alternativeBillingPeriods {
          amount
          billingPeriod
          currency
        }
      }
    }
  }
`;

export const getAccount = () =>
  axiosQuery<GetAccountResponse>("GetAccount", GET_ACCOUNT_QUERY);
