import { ReactNode } from "react";

import { styled } from "styled-components";
import { t } from "translations";

import { Flags, useFlag } from "apps-common/utils/featureFlags";

import { Row, Column } from "../../styles/layout";
import { RingImage } from "../../styles/media";
import { HeaderText, Paragraph, StepIndicator } from "../../styles/text";
import { breakpoints } from "../../themes/signup";
import { BackButton } from "../BackButton";
import { ErrorBanner } from "../ErrorBanner";
import { useIsDesktopView } from "../useIsDesktopView";

import {
  AppType,
  PageType,
  Step,
  StyledBaseHeader,
  StyledRingImageTitleHeaderContainer,
} from "./BaseHeader";
import { MiniBanner } from "./MiniBanner";
import { OuraHeaderLogo } from "./OuraHeaderLogo";

interface DesktopHeaderProps {
  appType: AppType;
  pageType: PageType;
  onBackClick?: () => void;
  title?: ReactNode;
  subTitle?: ReactNode;
  steps?: Step;
  error?: string;
  personalRingImage?: React.ReactNode;
  testId?: string;
  createdAt?: string;
  bannerText?: ReactNode;
}

const TiltedRingImage = styled(RingImage)`
  position: absolute;
  top: 70px;
  right: -60px;
  width: 240px;
  height: 240px;
  object-fit: cover;
`;

const AbsoluteRingImage = styled(RingImage)`
  position: absolute;
  right: 30px;
`;

export const DesktopHeader = ({
  pageType,
  appType,
  title,
  subTitle,
  onBackClick,
  error,
  steps,
  testId,
  createdAt,
  bannerText,
}: DesktopHeaderProps) => {
  const oreoFlag = useFlag(Flags.OREO);
  const [isDesktopView, windowWidth] = useIsDesktopView();
  const posterHeaderTextSize =
    windowWidth > parseInt(breakpoints.medium, 10) ? "xxxxlarge" : "xxxlarge"; // ipads and above use 4xlarges
  const welcomePageRingImagePath = oreoFlag
    ? "/blurred_silver.png"
    : "/oura-ring.webp";
  const completePageRingImagePath = oreoFlag
    ? "/blurred_gold.png"
    : "/oura-ring.webp";

  const renderHeader = () => {
    if (pageType === "landing") {
      return (
        <StyledBaseHeader $marginBottom="0">
          <StyledRingImageTitleHeaderContainer
            $padding="80px 0 0 0"
            $flexDirection="row"
          >
            <div style={{ paddingRight: "60px" }}>
              {appType === "hub" && (
                <HeaderText $fontSize={posterHeaderTextSize}>
                  {t("membership_hub_feature_name")}
                </HeaderText>
              )}
              {title && (
                <HeaderText
                  $fontSize={posterHeaderTextSize}
                  data-testid={testId}
                >
                  {title}
                </HeaderText>
              )}
              {subTitle && (
                <Paragraph $fontSize="large" $padding="20px 0 0 0i">
                  {subTitle}
                </Paragraph>
              )}
            </div>
            {appType === "hub" ? (
              <AbsoluteRingImage src="/oura-ring.webp" alt="Ouraring" />
            ) : (
              <RingImage src={welcomePageRingImagePath} alt="Ouraring" />
            )}
          </StyledRingImageTitleHeaderContainer>
        </StyledBaseHeader>
      );
    } else if (pageType === "create") {
      return (
        <StyledBaseHeader>
          <Row $alignItems="flex-end" $padding="0 0 60px 0">
            <OuraHeaderLogo height="29px" width="93px" app={appType} />
            {appType === "hub" && (
              <Paragraph $fontSize="small" $padding="0 0 0 20px">
                {t("membership_hub_feature_name")}
              </Paragraph>
            )}
          </Row>

          {error && <ErrorBanner>{error}</ErrorBanner>}
          {onBackClick && <BackButton onClick={onBackClick} />}
          {steps && (
            <StepIndicator>
              {t("membership_signup_step", {
                current_step: steps.current,
                total_steps: steps.total,
              })}
            </StepIndicator>
          )}
          {bannerText && appType === "signup" && (
            <MiniBanner marginBottom={"10px"} marginTop={"20px"}>
              {bannerText}
            </MiniBanner>
          )}
          {title && (
            <HeaderText
              $fontSize="xxlarge"
              $fontWeight="300"
              data-testid={testId}
            >
              {title}
            </HeaderText>
          )}
          {subTitle && (
            <Paragraph
              $textAlign="left"
              $fontSize="medium"
              $color="grayLightest"
              as="div"
              data-testid="pageSubtitle"
            >
              {subTitle}
            </Paragraph>
          )}
        </StyledBaseHeader>
      );
    } else if (pageType === "hubHome") {
      return (
        <StyledBaseHeader>
          <Row $alignItems="flex-end" $padding="0 0 60px 0">
            <OuraHeaderLogo height="29px" width="93px" app={appType} />
            <Paragraph $fontSize="small" $padding="0 0 0 20px">
              {t("membership_hub_feature_name")}
            </Paragraph>
          </Row>
          <HeaderText $fontSize="xxlarge" $fontWeight="300">
            {title}
          </HeaderText>
          {createdAt ? (
            <Paragraph $margin="0">
              {t("membership_hub_member_since", {
                date: new Date(createdAt).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }),
              })}
            </Paragraph>
          ) : null}
          <TiltedRingImage src="/stealth-ring.webp" alt="Ouraring" />
        </StyledBaseHeader>
      );
    } else if (pageType === "complete") {
      return (
        <StyledBaseHeader>
          <OuraHeaderLogo height="29px" width="93px" app={appType} />
          <StyledRingImageTitleHeaderContainer
            $padding="100px 0 0 0"
            $flexDirection="row"
          >
            <Column style={{ paddingRight: "20px", width: "60%" }}>
              {title && (
                <HeaderText
                  $fontSize={posterHeaderTextSize}
                  style={{ overflowWrap: "break-word" }}
                >
                  {title}
                </HeaderText>
              )}
              {subTitle && (
                <Paragraph
                  $fontSize="large"
                  $padding={isDesktopView ? "20px 0 0 0" : "0"}
                  data-testid="pageSubtitle"
                >
                  {subTitle}
                </Paragraph>
              )}
            </Column>
            {/* should be personalRingImage after MVP (signup complete page) */}
            <RingImage src={completePageRingImagePath} alt="Ouraring" />
          </StyledRingImageTitleHeaderContainer>
        </StyledBaseHeader>
      );
    } else if (pageType === "error") {
      return (
        <StyledBaseHeader>
          <Row $alignItems="flex-end">
            <OuraHeaderLogo height="29px" width="93px" app={appType} />
            {appType === "hub" && (
              <Paragraph $fontSize="small" $padding="0 0 0 20px">
                {t("membership_hub_feature_name")}
              </Paragraph>
            )}
          </Row>
        </StyledBaseHeader>
      );
    }
    return (
      <StyledBaseHeader>
        <OuraHeaderLogo height="29px" width="93px" app={appType} />
      </StyledBaseHeader>
    );
  };

  return renderHeader();
};
