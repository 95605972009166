import { gql } from "graphql-request";

import { axiosQuery } from "../utils/axiosQuery";

import type { Address, BillingPeriod } from "../types";
import type { LocaleSupported } from "translations";

const CREATE_SUBSCRIPTION_MUTATION = gql`
  mutation CreateSubscription(
    $membershipSubscriptionInput: ApiMembershipSubscriptionInput!
  ) {
    createMembershipSubscription(
      membershipSubscriptionInput: $membershipSubscriptionInput
    ) {
      subscriptionId
    }
  }
`;

export const createSubscription = async (
  paymentMethodId: string,
  userAddressForm: {
    billingAddress: Address;
    shippingAddress: Address;
  },
  paymentGateway: string,
  currency: string,
  billingPeriod: BillingPeriod,
  email: string,
  locale: LocaleSupported,
) => {
  const { billingAddress, shippingAddress } = userAddressForm;

  const billToContact = {
    ...billingAddress,
    personalEmail: email,
  };
  const shipToContact = {
    ...shippingAddress,
    personalEmail: email,
  };

  const membershipSubscriptionInput = {
    billToContact,
    shipToContact,
    paymentMethodId,
    currency,
    paymentGatewayName: paymentGateway,
    billingPeriodType: billingPeriod,
    locale,
  };

  await axiosQuery("CreateSubscription", CREATE_SUBSCRIPTION_MUTATION, {
    membershipSubscriptionInput,
  });
};
