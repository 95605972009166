import { useEffect, useRef } from "react";

import { type UseFormClearErrors, type UseFormSetValue } from "react-hook-form";
import { t } from "translations/src/LocaleContext";

import { Dialog } from "ui/components/Dialog";

import { Address, Problem } from "../types";

import { FormValues } from "./AddressForm/types";
import { AddressSuggestionResults } from "./AddressSuggestionResults";

interface AddressSuggestionModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  currentAddress: Address;
  isUnifiedAddress: boolean;
  setAddressFormData: UseFormSetValue<FormValues>;
  problems: Problem[];
  clearErrors: UseFormClearErrors<FormValues>;
}

export const AddressSuggestionModal = ({
  showModal,
  setShowModal,
  currentAddress,
  isUnifiedAddress,
  setAddressFormData,
  problems,
  clearErrors,
}: AddressSuggestionModalProps) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (showModal) {
      ref.current?.showModal();
    }
  }, [showModal]);

  const selectSuggestedAddress = () => {
    const correctedAddress = getCorrectAddress(problems, currentAddress);

    // set the address to billing as well if isUnifiedAddress toggle is on
    if (isUnifiedAddress) {
      setAddressFormData("billingAddress", correctedAddress, {
        shouldValidate: true,
      });
    }

    setAddressFormData("shippingAddress", correctedAddress, {
      shouldValidate: true,
    });

    clearErrors("serverError");
    ref.current?.close();
    setShowModal(false);
  };

  const editEnteredAddress = () => {
    ref.current?.close();
    setShowModal(false);
  };

  const getCorrectAddress = (problems: Problem[], address: Address) => {
    const correctAddress: Address = { ...address };
    problems.forEach((problem) => {
      if (problem.field === "address1") {
        correctAddress.address1 = problem.correctedValue ?? address.address1;
      } else if (problem.field === "city") {
        correctAddress.city = problem.correctedValue ?? address.city;
      } else if (problem.field === "state") {
        correctAddress.state = problem.correctedValue ?? address.state;
      } else if (problem.field === "postalCode") {
        correctAddress.postalCode =
          problem.correctedValue ?? address.postalCode;
      }
    });
    return correctAddress;
  };

  return (
    <Dialog
      ref={ref}
      icon={null}
      title={t("membership_hub_address_validation_suggestion_header")}
      textContents={[t("membership_hub_address_validation_suggestion_text")]}
      primaryButton={{
        text: t(
          "membership_hub_address_validation_suggestion_use_selected_address",
        ),
        onClick: selectSuggestedAddress,
      }}
      secondaryButton={{
        text: t(
          "membership_hub_address_validation_suggestion_edit_entered_address",
        ),
        onClick: editEnteredAddress,
      }}
    >
      <AddressSuggestionResults
        currentAddress={currentAddress}
        correctedAddress={getCorrectAddress(problems, currentAddress)}
        problems={problems}
      />
    </Dialog>
  );
};
