export const routes = {
  index: "/",
  address: "/address",
  product: "/product",
  paymentMethod: "/payment-method",
  zuoraCallback: "/payment-method/card-callback",
  paypalCallback: "/payment-method/paypal-callback",
  hsaCallback: "/payment-method/hsa-callback",
  complete: "/setup-complete",
} as const;
