import { useMutation } from "@tanstack/react-query";
import { type UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";

import { validateAddress } from "../../graphql/validateAddress";
import { Problem } from "../../types";

import { FormValues } from "./types";

export const useAddressTaxValidation = (
  formMethods: UseFormReturn<FormValues>,
) => {
  const { renderValidationFailures } = useHandleFieldErrors(formMethods);
  return useMutation({
    mutationKey: ["validateAddress"],
    mutationFn: validateAddress,
    throwOnError: true,
    onSuccess: ({ success, problems }) => {
      if (!success) {
        renderValidationFailures(problems);
      }
    },
  });
};

const useHandleFieldErrors = (formMethods: UseFormReturn<FormValues>) => {
  const intl = useIntl();
  const { setError: setFieldError, getValues } = formMethods;

  const isUnifiedAddress = getValues("isUnifiedAddress");

  const addressKey = isUnifiedAddress ? "billingAddress" : "shippingAddress";

  const renderValidationFailures = (problems: Problem[]): void => {
    const fieldErrors = problems.filter((p) => p.field !== null);
    // Fixing the single mismatch between input and Avalara field names
    const fieldNames = fieldErrors.map((p) => {
      if (p.field === "address1") return "address";
      return p.field;
    });

    const bannerMessage =
      fieldErrors.length === 0
        ? // If there are only general errors, render message in the banner
          intl.formatMessage(
            {
              id: "membership_hub_address_page_validation_general_error",
            },
            {
              errorMessage: problems?.[0]?.message ?? "Unknown",
            },
          )
        : // Otherwise generate banner error that lists all fields with errors
          intl.formatMessage(
            {
              id: "membership_hub_address_page_validation_error",
            },
            {
              fields: fieldNames.join(", "),
            },
          );

    // Set the banner error
    setFieldError("serverError", {
      type: "taxValidationError",
      message: bannerMessage,
    });

    // Set all field errors to corresponding input error label
    const defaultFieldError = intl.formatMessage({
      id: "membership_hub_address_page_invalid_field",
    });
    fieldErrors.forEach(({ field, message }) => {
      setFieldError(`${addressKey}.${field!}`, {
        type: "validate",
        message: message ?? defaultFieldError,
      });
    });
  };

  return {
    renderValidationFailures,
  };
};
