import { PropsWithChildren } from "react";

import { styled } from "styled-components";

import { media } from "ui/themes/signup";

import { Background } from "../../styles/layout";
import { Paragraph } from "../../styles/text";

interface Props extends PropsWithChildren {
  marginBottom?: string;
  marginTop?: string;
}
const PaddedBg = styled(Background)`
  padding: 10px;
  border-radius: 4px;
  ${media.xlarge`
     width: 60%;
  `}

  p {
    margin: 0;
    font-weight: bold;
  }
`;

export const MiniBanner = (props: Props) => (
  <PaddedBg $marginBottom={props.marginBottom} $marginTop={props.marginTop}>
    <Paragraph $fontSize="small">{props.children}</Paragraph>
  </PaddedBg>
);
