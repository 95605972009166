import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import {
  createAddressSlice,
  createMemberSlice,
  createHubSlice,
  createPaymentErrorSlice,
  createProductSlice,
  type AddressSlice,
  type HubSlice,
  type MemberSlice,
  type ProductSlice,
  type PaymentErrorSlice,
} from "apps-common/store";

export type HubStore = AddressSlice &
  HubSlice &
  MemberSlice &
  PaymentErrorSlice &
  ProductSlice;

export const useStore = create<HubStore>()(
  persist(
    (...a): HubStore => ({
      ...createAddressSlice(...a),
      ...createMemberSlice(...a),
      ...createPaymentErrorSlice(...a),
      ...createProductSlice(...a),
      ...createHubSlice(...a),
    }),
    { name: "hub", storage: createJSONStorage(() => sessionStorage) },
  ),
);
