import { useEffect } from "react";

import { t } from "translations";

import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { track } from "apps-common/utils/analytics";
import { isGen2User, isPayingUser } from "apps-common/utils/helpers";
import { logger } from "apps-common/utils/logger";
import { Header, Loader } from "ui";

import { useStore } from "../store";

const useTrackFunnelCompleted = () => {
  useEffect(() => {
    logger.info("Signup Funnel Completed");
    track({ event: "Signup Funnel Completed" });
  }, []);
};

const useLogErrors = () => {
  const userType = useStore((state) => state.userType);
  const { data, error, refetch, isFetching } = useGetAccount();
  const hasSubscriptions = Boolean(data?.account.subscriptions?.length);
  const isGen2 = isGen2User(userType);

  useEffect(() => {
    if (!isFetching) {
      if (error) {
        // Error in fetch
        // TODO: If we want log accountid here, it has to be queried from backend, fe. /me endpoint
        logger.warn(`Error in fetching account details`, { error });
      } else if (!hasSubscriptions && !isGen2) {
        // Just missing subscription
        logger.warn(`No subscriptions found for account`, {
          hasSubscriptions,
          isGen2,
        });
      }
    }
  }, [error, hasSubscriptions, isGen2, isFetching, refetch]);
};

export const SignUpComplete = () => {
  const { data, isFetching } = useGetAccount();
  useLogErrors();
  useTrackFunnelCompleted();
  const userType = useStore((state) => state.userType);

  if (isFetching) {
    return <Loader />;
  }

  const isGen2 = isGen2User(userType);

  const FALLBACK_NAME = t("membership_signup_fallback_name");
  const nameToShow = isPayingUser(userType)
    ? (data?.account?.shipToContact?.firstName ?? FALLBACK_NAME)
    : FALLBACK_NAME;

  const TITLE = t("membership_signup_account_created_title", {
    name: nameToShow,
  });

  const SUB_TITLE = isGen2
    ? t("membership_signup_account_created_success_message_gen2")
    : t("membership_signup_account_created_success_message");
  return (
    <Header
      appType="signup"
      pageType="complete"
      title={TITLE}
      subTitle={SUB_TITLE}
      testId="signup-complete-header"
    />
  );
};
