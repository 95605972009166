import { UseQueryResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  AccountApi,
  Configuration,
  SignupResponse,
  GetSignupResponse,
  SignupRequestBodyAcceptHealthDataProcessingEnum,
  LoginResponse,
  LoginPasswordRequestBody,
  UpdateEmailRequestBody,
  UpdatePasswordRequestBody,
  VerifyEmailResponseBody,
  ApiErrorResponse,
  ResetPasswordRequestBody,
  AccountInformationResponse,
  LoginEndResponse,
  AuthenticationResponse,
} from "oura-account-api-client";

import { SignupInfo } from "../store";
import { AccountApiError } from "../utils/AccountApiError";
import { logger } from "../utils/logger";

const API_URL: string = import.meta.env.VITE_ACCOUNT_API_URL;
const api = new AccountApi(new Configuration({ basePath: API_URL }));

function handleApiError(error: unknown): never {
  if (axios.isAxiosError<ApiErrorResponse>(error) && error.response?.data) {
    if (error.response?.data) {
      const {
        response: {
          data: { errorCode, message, detail },
        },
      } = error;
      throw new AccountApiError(message, errorCode, detail);
    }
  }
  throw error;
}

const getSignup = async (token: string) => {
  const reqBody = { token };
  const response = await api.getSignup(reqBody).catch(handleApiError);
  return response.data;
};

/** Getting email by signup token */
export const useGetSignup = (token: string) =>
  useQuery<GetSignupResponse>({
    queryKey: ["getSignup", token],
    queryFn: () => getSignup(token),
    retry: false,
    staleTime: Infinity,
    enabled: !!token,
  });

interface CreateUserPayload {
  password: string;
  userAgreement: SignupRequestBodyAcceptHealthDataProcessingEnum;
  marketingAgreement: boolean;
  signupInfo: SignupInfo;
}

export const createUser = async ({
  password,
  userAgreement,
  signupInfo,
}: CreateUserPayload): Promise<SignupResponse> => {
  const { termsOfServiceVersion, privacyPolicyVersion, token } = signupInfo;
  const reqBody = {
    signupRequestBody: {
      password: password,
      acceptHealthDataProcessing: userAgreement,
      termsOfServiceVersion,
      privacyPolicyVersion,
      token,
    },
  };
  const resp = await api
    .postSignup(reqBody, { withCredentials: true })
    .catch(handleApiError);

  logger.setContextProperty("accountId", resp.data.accountId);
  return resp.data;
};

export const loginUser = async ({
  email,
  password,
}: LoginPasswordRequestBody): Promise<LoginResponse> => {
  const reqBody = {
    loginPasswordRequestBody: {
      email,
      password,
    },
  };
  const response = await api
    .postAccountPasswordLogin(reqBody, { withCredentials: true })
    .catch(handleApiError);

  logger.setContextProperty("accountId", response.data.accountId);
  return response.data;
};

export const validateJwt = async (jwtToken: string): Promise<void> => {
  await api
    .postJwtAccess({
      headers: { Authorization: "Bearer " + jwtToken },
    })
    .catch(handleApiError);

  return;
};

export const refreshTokens = async (): Promise<AuthenticationResponse> => {
  const response = await api
    .postJwtRefresh({
      withCredentials: true,
    })
    .catch(handleApiError);

  logger.setContextProperty("accountId", response.data.accountId);
  return response.data;
};

export const updatePassword = async ({
  password,
  newPassword,
}: UpdatePasswordRequestBody) => {
  const reqBody = { updatePasswordRequestBody: { password, newPassword } };
  const response = await api
    .updatePassword(reqBody, {
      withCredentials: true,
    })
    .catch(handleApiError);
  return response.status;
};

export const updateEmail = async ({
  password,
  email,
}: UpdateEmailRequestBody) => {
  const reqBody = { updateEmailRequestBody: { password, email } };
  const response = await api
    .updateEmail(reqBody, { withCredentials: true })
    .catch(handleApiError);
  return response.status;
};

const verifyEmail = async (token: string) => {
  const reqBody = {
    verifyEmailRequestBody: { token },
  };
  const response = await api.verifyEmail(reqBody).catch(handleApiError);
  return response.data;
};

export const useVerifyNewEmail = (token: string) =>
  useQuery<VerifyEmailResponseBody>({
    queryKey: ["verifyEmail", token],
    queryFn: () => verifyEmail(token),
    retry: false,
    staleTime: Infinity,
    enabled: !!token,
  });

export const sendResetPasswordRequest = async (email: string) => {
  const reqBody = {
    requestResetPasswordBody: { email },
  };
  await api.requestResetPassword(reqBody).catch(handleApiError);
};

export const resetPassword = async ({
  token,
  password,
}: ResetPasswordRequestBody) => {
  const reqBody = {
    resetPasswordRequestBody: { token, password },
  };
  const response = await api.resetPassword(reqBody).catch(handleApiError);

  logger.setContextProperty("accountId", response.data.accountId);
  return response.data;
};

export const logoutUser = async () => {
  const logoutResponse = await api
    .postAccountLogout(
      {
        logoutRequestBody: {
          redirectUrl: import.meta.env.VITE_URL + "/login/?next=/",
        },
      },
      { withCredentials: true },
    )
    .catch(handleApiError);
  return logoutResponse.data;
};
export interface InitLoginResponse {
  authorizationRequestUrl: string;
}
/** MOI queries */

export const useInitLogin = (
  redirectUri: string,
  enabled: boolean,
): UseQueryResult<InitLoginResponse> =>
  useQuery({
    queryKey: ["initLogin", redirectUri],
    queryFn: async (): Promise<InitLoginResponse> => {
      const response = await api.initiateLogin(
        { initLoginRequest: { redirectUri } },
        {
          withCredentials: true,
        },
      );
      return response.data;
    },
    enabled,
  });

export const useCompleteLogin = (
  redirectUri: string,
  code: string,
  state: string,
  enabled: boolean,
): UseQueryResult<LoginEndResponse> =>
  useQuery({
    queryKey: ["completeLogin", redirectUri, code, state],
    queryFn: async (): Promise<LoginEndResponse> => {
      const response = await api.endLogin(
        { loginEndRequest: { code, state, redirectUri } },
        { withCredentials: true },
      );
      return response.data;
    },
    enabled,
  });
export const useGetAccountInfo =
  (): UseQueryResult<AccountInformationResponse> =>
    useQuery({
      queryKey: ["accountInfo"],
      queryFn: async (): Promise<AccountInformationResponse> => {
        const response = await api
          .accountMe({ withCredentials: true })
          .catch(handleApiError);
        return response.data;
      },
    });
