import { t } from "translations";

export const getSignupBannerText = (
  isPrepaid: boolean,
  prepaidMonths: number,
) =>
  isPrepaid
    ? t("signup_account_setup_banner_prepaid_text", {
        months: prepaidMonths,
      })
    : t("signup_account_setup_banner_default_text");
