import { MessageKey } from "translations";

const ERROR_CODES: Record<string, MessageKey> = {
  Attempt_Exceed_Limitation: "checkout_payment_max_attempts",
  BusinessValidationError: "checkout_payment_generic_error",
  Decryption_Error: "checkout_payment_generic_error",
  ExceededMaxLength: "checkout_payment_generic_error",
  GatewayTransactionError: "checkout_payment_generic_error",
  GeneralSystemError: "checkout_payment_generic_error",
  HostedPageFieldValidationError: "checkout_payment_generic_error",
  Invalid_PaymentGateway: "checkout_payment_generic_error",
  Invalid_Request_Method: "checkout_payment_generic_error",
  Invalid_Security: "checkout_payment_generic_error",
  InvalidFormat: "checkout_payment_invalid_card_error",
  NullValue: "checkout_payment_generic_error",
  ReCaptcha_Validation_Failed: "checkout_payment_captcha_error",
  Submit_Too_Quick: "checkout_payment_max_attempts",
  Validate_Dynamic_Params_Failed: "checkout_payment_generic_error",
  ThreeDs2_Authentication_Exception: "checkout_payment_3ds_error",
};

const ERROR_MESSAGES: Record<string, MessageKey> = {
  "Transaction declined.1000 - Gateway Rejected: avs":
    "checkout_payment_avs_error",
  "Transaction declined.GATEWAY_REJECTED - Gateway Rejected: risk_threshold":
    "checkout_payment_max_attempts",
  "Transaction declined.2014 - Processor Declined - Fraud Suspected":
    "checkout_payment_fraud_suspected",
  "Transaction declined.GATEWAY_REJECTED - Authentication failed, please check your password.":
    "checkout_payment_authentication_error",
  "Transaction declined.2038 - Processor Declined":
    "checkout_payment_bank_rejected",
  "Transaction declined.z_verify_card_failed - Verify card failed.":
    "checkout_payment_bank_rejected",
  "Transaction declined.2010 - Card Issuer Declined CVV":
    "checkout_payment_bank_rejected",
  "Transaction declined.2057 - Issuer or Cardholder has put a restriction on the card":
    "checkout_payment_bank_rejected",
  "Transaction declined.2044 - Declined - Call Issuer":
    "checkout_payment_bank_rejected",
  "Transaction declined.2001 - Insufficient Funds":
    "checkout_payment_funds_error",
  "Transaction declined.2047 - Call Issuer. Pick Up Card.":
    "checkout_payment_bank_rejected",
  "Transaction declined.2015 - Transaction Not Allowed":
    "checkout_payment_bank_rejected",
  "Transaction declined.2002 - Limit Exceeded":
    "checkout_payment_bank_rejected",
  "Transaction declined.TRANSACTION_PAYMENT_ - Unknown or expired payment_method_nonce.":
    "checkout_payment_bank_rejected",
  "Transaction declined.2000 - Do Not Honor": "checkout_payment_bank_rejected",
  "Transaction declined.2007 - No Account": "checkout_payment_bank_rejected",
  "Transaction declined.TRANSACTION_THREE_D_ - Merchant account does not support 3D Secure transactions for card type.":
    "checkout_payment_bank_rejected",
  "User has insufficient funds to complete the purchase":
    "checkout_payment_funds_error",
  "Invalid Credit Card Number.": "payment_method_failrue_invalid_card_number",
  "ACH Bank Name is required.": "payment_method_failure_ach_bank_name_required",
  "Card Holder Name is required.":
    "payment_method_failure_card_holder_name_required",
  "Credit Card Type is required.": "payment_method_failure_card_type_required",
  "Expiration date must be a future date.":
    "payment_method_failure_expiration_date_must_be_future_date",
  "Payment gateway is not active. It needs to be activated first.":
    "payment_method_failure_inactive_gateway",
  "Invalid Email": "payment_method_failure_invalid_email",
  "Invalid Month": "payment_method_failure_invalid_month",
  "Invalid Year, should be 4-digital year value.":
    "payment_method_failure_invalid_year",
  "State/Province should be ISO standard state or province. - is not an ISO state/province of United States.":
    "payment_method_failure_non_iso_state",
  "security code should be numeric.":
    "payment_method_failure_non_numeric_security_code",
  "ACH Bank Account Number can not be null and must be digit.":
    "payment_method_failure_null_ach_bank_account_number",
  "ACH Bank ABA Code can not be null and must be digits string.":
    "payment_method_failure_null_ach_or_aba_code",
  "Please try another payment method.":
    "payment_method_failure_please_try_another_method",
  "The length of ACH Bank Account Name can not be longer than 70 characters.":
    "payment_method_failure_too_long_ach_name",
  "The length of ACH Bank Account Number can not be longer than 30 characters.":
    "payment_method_failure_too_long_ach_number",
  "Once a payment method associated with an account it can not be associated with another account":
    "payment_method_failure_too_many_associations",
  "You cannot create more than 100 payment methods for each customer account. If you need to create this payment method, please delete some others first.":
    "payment_method_failure_too_many_payment_methods_for_account",
};

const GENERAL_ERROR_MESSAGE = "checkout_payment_generic_error";

export interface PaymentError {
  message: string;
  code?: string;
  name?: string;
}

export const getPaymentErrorMessageKey = ({
  code,
  message,
}: PaymentError): MessageKey => {
  if (ERROR_MESSAGES[message]) {
    return ERROR_MESSAGES[message];
  } else if (code && ERROR_CODES[code]) {
    return ERROR_CODES[code];
  } else {
    return GENERAL_ERROR_MESSAGE;
  }
};
